import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

const BarContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
    flex: "1",
    "& .MuiLinearProgress-root": {
        background: "transparent",
        height: "20px",
        width: "40px",
    },
    "& .MuiLinearProgress-bar": {
        background: "linear-gradient(180deg, #8ED974 0%, #4CA456 100%)",
    },
}));

const BarLabel = styled(Typography)(({ theme }) => ({
    // color: "white",
}));

const BarChart = ({ goalsData }) => {
    const renderBars = () => {
        return goalsData.map(
            (
                /** @type {{ maturityLevel: number; strategyID: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal; }} */ data,
                /** @type {React.Key} */ index
            ) => (
                <BarContainer key={index}>
                    <LinearProgress
                        variant="determinate"
                        value={data.maturityLevel * 10}
                        sx={{ transform: "rotate(270deg)" }}
                    />
                    <BarLabel>
                        {data.strategyID && data.strategyID.toString().split('-')[1]}
                    </BarLabel>
                </BarContainer>
            )
        );
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>{renderBars()}</Box>
    );
};

export default BarChart;
