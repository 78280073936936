// @ts-nocheck
import {
    Box,
    Button,
    Paper,
    Typography,
    FormControlLabel,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { postPractices } from "../../../../api/rules";

const options = ["None", "Partially", "Largely", "Fully", "Not Applicable"];

const scoreMap = {
    None: 0,
    Partially: 1,
    Largely: 2,
    Fully: 3,
    "Not Applicable": 4,
};

export default function Practices3(props) {
    const { setActiveStepMain } = props;
    const [strategies, setStrategies] = useState();
    const [practices, setPractices] = useState();

    useEffect(() => {
        const strategies = JSON.parse(localStorage.getItem("strategies"));
        if (strategies) {
            setStrategies(strategies);
        }
    }, []);

    const mapStrategyID = (responseData) => {
        return responseData.map((item) => {
            const strategyNumber = parseInt(item.practiceID.split("-")[1], 10);
            const strategyID = `GS-${strategyNumber}`;
            return { ...item, strategyID };
        });
    };

    useEffect(() => {
        const practices = JSON.parse(localStorage.getItem("practices"));
        if (practices) {
            const processedData = mapStrategyID(practices);
            setPractices(processedData);
        }
    }, []);

    const [answers, setAnswers] = useState({});

    const handleChange = (questionId, optionValue) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: optionValue,
        }));
    };

    const handleSubmit = async () => {
        if (Object.keys(answers).length !== practices.length) {
            alert("Please answer all questions");
            return;
        }
        const output = Object.entries(answers).map(
            ([practiceID, achievementScore]) => ({
                practiceID,
                achievementScore: scoreMap[achievementScore],
            })
        );
        const response = await postPractices({ data: output });
        if (response.ok === true) {
            localStorage.setItem("end_results", JSON.stringify(response.data));
            setActiveStepMain(3);
        }
    };

    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                // display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                borderRadius: "8px",
                px: "24px",
                mb: "16px",
                pt: "24px",
            }}
        >
            {strategies && (
                <Box>
                    <Typography
                        fontWeight={600}
                        fontSize={"24px"}
                        fontFamily={"Sora"}
                        textAlign={"center"}
                        sx={{
                            mb: "24px",
                        }}
                        height="100%"
                    >
                        How is the implementation of these{" "}
                        <span
                            style={{
                                color: `rgba(76, 164, 86, 1)`,
                            }}
                        >
                            Green Practices
                        </span>{" "}
                        in your organization?
                    </Typography>
                    {strategies.map((strategy, index) => (
                        <Box marginBottom={4}>
                            <Typography fontWeight={700} fontSize={"20px"}>
                                Practices in {strategy.strategyName}
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            {options.map((option, index) => (
                                                <TableCell key={index}>
                                                    {option}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {practices
                                            .filter(
                                                (practice) =>
                                                    practice.strategyID ===
                                                    strategy.strategyID
                                            )
                                            .map((practice) => (
                                                <TableRow
                                                    key={practice.practiceID}
                                                >
                                                    <TableCell>
                                                        {
                                                            practice.practiceDescription
                                                        }
                                                    </TableCell>
                                                    {options.map(
                                                        (option, index) => (
                                                            <TableCell
                                                                key={index}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Radio />
                                                                    }
                                                                    value={
                                                                        option
                                                                    }
                                                                    checked={
                                                                        answers[
                                                                            practice
                                                                                .practiceID
                                                                        ] ===
                                                                        option
                                                                    }
                                                                    onChange={() =>
                                                                        handleChange(
                                                                            practice.practiceID,
                                                                            option
                                                                        )
                                                                    }
                                                                    label=""
                                                                />
                                                            </TableCell>
                                                        )
                                                    )}
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ))}

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <Button
                            color="success"
                            variant="contained"
                            sx={{
                                boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                                borderRadius: "24px",
                                width: "40%",
                                height: "56px",
                                textTransform: "none",
                                mb: "24px",
                            }}
                            onClick={handleSubmit}
                            disabled={
                                Object.keys(answers).length !== practices.length
                            }
                        >
                            <Typography fontWeight={600} fontSize={"24px"}>
                                Continue to Create Action Plan
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            )}
        </Paper>
    );
}
