import { useState } from "react";
import Strategies1 from "./Strategies1";
import Strategies2 from "./Strategies2";
import Strategies3 from "./Strategies3";

export default function Strategies(props) {
    const { setActiveStepMain } = props;
    const [activeStep, setActiveStep] = useState(0);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <Strategies1 setActiveStep={setActiveStep} />;
            case 1:
                return <Strategies2 setActiveStep={setActiveStep} />;
            case 2:
                return <Strategies3 setActiveStepMain={setActiveStepMain} />;
            default:
                throw new Error("Unknown step");
        }
    }
    return <>{getStepContent(activeStep)}</>;
}
