import request from "./base";

export const fetchSummary = async () => {
    try {
        const res = await request.get(`/rules/summary`);
        if (res.data && res.data.ok) {
            return res.data;
        } else {
            return { data: [] };
        }
    } catch (err) {
        console.log(err.message);
    }
}

export const fetchGoals = async ({ assessment }) => {
    try {
        const res = await request.get(`/rules/goals?assessment=${assessment}`);
        if (res.data && res.data.ok) {
            return res.data;
        } else {
            return { data: [] };
        }
    } catch (err) {
        console.log(err.message);
    }
};

export const postGoals = async (data) => {
    try {
        const res = await request.post("/rules/goals", data);
        if (res.data && res.data.ok) {
            return res.data;
        } else {
            return { data: [] };
        }
    } catch (err) {
        console.log(err.message);
    }
};

export const postStrategies = async (data) => {
    try {
        const res = await request.post("/rules/strategy", data);
        if (res.data && res.data.ok) {
            return res.data;
        } else {
            return { data: [] };
        }
    } catch (err) {
        console.log(err.message);
    }
};

export const postPractices = async (data) => {
    try {
        const res = await request.post("/rules/practice", data);
        if (res.data && res.data.ok) {
            return res.data;
        } else {
            return { data: [] };
        }
    } catch (err) {
        console.log(err.message);
    }
}

export const getActions = async (data) => {
    try {
        const res = await request.get('/rules/actions')
        if (res.data && res.data.ok) {
            return res.data
        } else {
            return { data: [] }
        }
    } catch (err) {
        console.log(err.message)
    }
}