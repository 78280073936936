export const mockGoals = {
    data: [
        {
            goals: "Align Sustainability with Organizational Goals",
            achievement: "80",
            maturity: "4",
            strategiesMaturity: [
                {
                    strategies: "Strategy 1",
                    maturity: "3",
                },
                {
                    strategies: "Strategy 2",
                    maturity: "2",
                },
                {
                    strategies: "Strategy 3",
                    maturity: "4",
                },
                {
                    strategies: "Strategy 4",
                    maturity: "1",
                },
                {
                    strategies: "Strategy 5",
                    maturity: "5",
                },
            ],
        },
        {
            goals: "Reduce Energy Consumption",
            achievement: "60",
            maturity: "3",
            strategiesMaturity: [
                {
                    strategies: "Strategy 1",
                    maturity: "2",
                },
                {
                    strategies: "Strategy 2",
                    maturity: "3",
                },
                {
                    strategies: "Strategy 3",
                    maturity: "4",
                },
                {
                    strategies: "Strategy 4",
                    maturity: "1",
                },
                {
                    strategies: "Strategy 5",
                    maturity: "5",
                },
            ],
        },
        {
            goals: "Minimize Waste Generation",
            achievement: "75",
            maturity: "3",
            strategiesMaturity: [
                {
                    strategies: "Strategy 1",
                    maturity: "4",
                },
                {
                    strategies: "Strategy 2",
                    maturity: "2",
                },
                {
                    strategies: "Strategy 3",
                    maturity: "3",
                },
                {
                    strategies: "Strategy 4",
                    maturity: "1",
                },
                {
                    strategies: "Strategy 5",
                    maturity: "5",
                },
            ],
        },
        {
            goals: "Improve Water Management",
            achievement: "90",
            maturity: "4",
            strategiesMaturity: [
                {
                    strategies: "Strategy 1",
                    maturity: "5",
                },
                {
                    strategies: "Strategy 2",
                    maturity: "4",
                },
                {
                    strategies: "Strategy 3",
                    maturity: "2",
                },
                {
                    strategies: "Strategy 4",
                    maturity: "3",
                },
                {
                    strategies: "Strategy 5",
                    maturity: "5",
                },
            ],
        },
        {
            goals: "Enhance Employee Engagement",
            achievement: "70",
            maturity: "2",
            strategiesMaturity: [
                {
                    strategies: "Strategy 1",
                    maturity: "1",
                },
                {
                    strategies: "Strategy 2",
                    maturity: "2",
                },
                {
                    strategies: "Strategy 3",
                    maturity: "3",
                },
                {
                    strategies: "Strategy 4",
                    maturity: "4",
                },
                {
                    strategies: "Strategy 5",
                    maturity: "5",
                },
            ],
        },
        {
            goals: "Implement Sustainable Procurement Practices",
            achievement: "85",
            maturity: "5",
            strategiesMaturity: [
                {
                    strategies: "Strategy 1",
                    maturity: "5",
                },
                {
                    strategies: "Strategy 2",
                    maturity: "4",
                },
                {
                    strategies: "Strategy 3",
                    maturity: "3",
                },
                {
                    strategies: "Strategy 4",
                    maturity: "2",
                },
                {
                    strategies: "Strategy 5",
                    maturity: "1",
                },
            ],
        },
    ],
};
