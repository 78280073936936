import { useState } from "react";
import Practices1 from "./Practices1";
import Practices2 from "./Practices2";
import Practices3 from "./Practices3";

export default function Strategies(props) {
    const { setActiveStepMain } = props;
    const [activeStep, setActiveStep] = useState(0);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <Practices1
                        setActiveStep={setActiveStep}
                        setActiveStepMain={setActiveStepMain}
                    />
                );
            case 1:
                return <Practices2 setActiveStep={setActiveStep} />;
            case 2:
                return <Practices3 setActiveStepMain={setActiveStepMain} />;
            default:
                throw new Error("Unknown step");
        }
    }
    return <>{getStepContent(activeStep)}</>;
}
