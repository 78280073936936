import { useState } from "react";
import {
    Box,
    Button,
    Container,
    LinearProgress,
    Paper,
    Step,
    StepLabel,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import SetGoals from "./components/SetGoals/SetGoals";
import Strategies from "./components/Strategies/Strategies";
import Practices from "./components/Practices/Practices";
import ActionPlan from "./components/ActionPlan";

const steps = [
    "Set Goals",
    "Assess Strategies",
    "Assess Practices",
    "Create Action Plan",
];

export default function AssessmentPage() {
    const [activeStepMain, setActiveStepMain] = useState(0);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <SetGoals setActiveStepMain={setActiveStepMain} />;
            case 1:
                return <Strategies setActiveStepMain={setActiveStepMain} />;
            case 2:
                return <Practices setActiveStepMain={setActiveStepMain} />;
            case 3:
                return <ActionPlan />;
            default:
                throw new Error("Unknown step");
        }
    }

    const handleNext = () => {
        setActiveStepMain(activeStepMain + 1);
    };

    const handleBack = () => {
        setActiveStepMain(activeStepMain - 1);
    };

    return (
        <Container
            sx={{
                width: 1,
                mb: 5,
            }}
        >
            <Paper
                sx={{
                    boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                    minHeight: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    borderRadius: "8px",
                }}
            >
                <Stepper activeStep={activeStepMain} alternativeLabel>
                    {steps.map((label) => (
                        <Step
                            key={label}
                            sx={{
                                "& .MuiSvgIcon-root": {
                                    color: "green !important",
                                },
                                "& .MuiSvgIcon-root .Mui-active": {
                                    color: "green !important",
                                },
                                "& .MuiSvgIcon-root .Mui-completed": {
                                    color: "green !important",
                                },
                                fontFamily: "Sora",
                            }}
                        >
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Paper>
            <LinearProgress
                variant="determinate"
                value={activeStepMain * (100 / steps.length)}
                sx={{
                    borderRadius: "8px",
                    mb: "32px",
                    backgroundColor: "transparent",
                    "& .MuiLinearProgress-bar": {
                        background:
                            "linear-gradient(180deg, #8ED974 0%, #4CA456 100%)",
                    },
                }}
            />
            {activeStepMain === steps.length ? (
                <div>end</div>
            ) : (
                <>{getStepContent(activeStepMain)}</>
            )}
            {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    onClick={handleNext}
                    color="success"
                    sx={{ mt: 3, ml: 1 }}
                >
                    {activeStepMain === steps.length - 1
                        ? "Place order"
                        : "Next"}
                </Button>
            </Box> */}
        </Container>
    );
}
