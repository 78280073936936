// @ts-nocheck
import { Box, Button, Paper, Typography, Slider, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchGoals, postGoals } from "../../../../api/rules";

export default function SetGoals4(props) {
    const { setActiveStepMain } = props;
    const [goals, setGoals] = useState();
    const [listOfGoals, setListOfGoals] = useState();
    const [sliderValues, setSliderValues] = useState();
    const [loading, setLoading] = useState(false);

    const handleChange = (event, newValue, index) => {
        const newSliderValues = [...sliderValues];
        newSliderValues[index] = newValue;
        setSliderValues(newSliderValues);
    };

    useEffect(() => {
        const goals = JSON.parse(localStorage.getItem("goals"));
        if (goals) {
            setSliderValues(Array(goals.length).fill(5));
            setGoals(goals);
        }
    }, []);

    const fetch = async () => {
        try {
            setLoading(true);
            const data = await fetchGoals({ assessment: false });
            setListOfGoals(data.data);
        } catch (err) {
            console.log(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    const handleSubmit = async () => {
        const data = sliderValues.map((value, index) => ({
            goalID: goals[index],
            goalScore: value,
        }));
        const response = await postGoals({ data: data });
        if (response.ok === true) {
            localStorage.setItem("strategies", JSON.stringify(response.data));
            setActiveStepMain(1);
        }
    };

    const marks = [
        { value: 1, label: "1", markLabel: "1" },
        { value: 2, label: "2", markLabel: "" },
        { value: 3, label: "3", markLabel: "" },
        { value: 4, label: "4", markLabel: "" },
        { value: 5, label: "5", markLabel: "" },
        { value: 6, label: "6", markLabel: "" },
        { value: 7, label: "7", markLabel: "" },
        { value: 8, label: "8", markLabel: "" },
        { value: 9, label: "9", markLabel: "" },
        { value: 10, label: "10", markLabel: "10" },
    ];

    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                // display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                borderRadius: "8px",
                px: "24px",
                mb: "16px",
                height: "500px",
                pt: "24px",
            }}
        >
            {/* {loading && <Typography>Loading...</Typography>} */}
            {!loading && goals && listOfGoals && (
                <Box>
                    <Typography
                        fontWeight={600}
                        fontSize={"24px"}
                        fontFamily={"Sora"}
                        textAlign={"center"}
                        sx={{
                            mb: "24px",
                        }}
                        height="100%"
                    >
                        How important is this{" "}
                        <span
                            style={{
                                color: `rgba(76, 164, 86, 1)`,
                            }}
                        >
                            Green Goals
                        </span>{" "}
                        to your organization?
                    </Typography>
                    <Box
                        sx={{
                            overflowY: "scroll",
                            height: "35vh",
                        }}
                    >
                        {goals.map((goal, index) => (
                            <Box textAlign={"center"} key={goal}>
                                <Typography
                                    fontWeight={600}
                                    fontSize={"32px"}
                                    fontFamily={"Sora"}
                                >
                                    <span
                                        style={{
                                            fontSize: "20px",
                                        }}
                                    >
                                        Goal {index + 1}/{goals.length}:{" "}
                                    </span>
                                    {
                                        listOfGoals.find(
                                            (logoal) =>
                                                logoal.goalID === goals[index]
                                        ).goalName
                                    }
                                </Typography>
                                <Typography
                                    fontSize={"16px"}
                                    fontFamily={"Sora"}
                                    color="#8F8F8F"
                                >
                                    {
                                        listOfGoals.find(
                                            (logoal) =>
                                                logoal.goalID === goals[index]
                                        ).goalDescription
                                    }
                                </Typography>
                                <div
                                    style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        width: "80%",
                                        marginTop: 20,
                                    }}
                                >
                                    <Slider
                                        value={sliderValues[index]}
                                        onChange={(event, newValue) =>
                                            handleChange(event, newValue, index)
                                        }
                                        step={1}
                                        marks={marks}
                                        min={1}
                                        max={10}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={(value) => `${value}`}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            fontWeight={600}
                                            sx={{
                                                fontSize: "0.8rem",
                                            }}
                                        >
                                            Least Important
                                        </Typography>
                                        <Typography
                                            fontWeight={600}
                                            sx={{
                                                fontSize: "0.8rem",
                                            }}
                                        >
                                            Very Important
                                        </Typography>
                                    </div>
                                </div>
                                <Divider />
                            </Box>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <Button
                            color="success"
                            variant="contained"
                            sx={{
                                boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                                borderRadius: "24px",
                                width: "20%",
                                height: "56px",
                                mt: "24px",
                                textTransform: "none",
                            }}
                            onClick={handleSubmit}
                        >
                            <Typography fontWeight={600} fontSize={"24px"}>
                                Submit
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            )}
        </Paper>
    );
}
