import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from "../../components/Header/Header";
import DashboardPage from "../Dashboard/DashboardPage";
import AssessmentPage from "../Assessment/AssessmentPage";
import ReportPage from "../Report/ReportPage";
import LoginPage from "../Login/Login";
import RegisterPage from "../Register/RegisterPage";
import NotFoundPage from "../NotFound/NotFoundPage";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { Box, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { login, register } from '../../api/users';

const theme = createTheme({
    typography: {
        fontFamily: "Sora",
    },
});

export const AuthContext = React.createContext(null)

const ProtectedRoute = ({ isAuthPage = false, children }) => {
    const { token } = React.useContext(AuthContext);

    if (!token && !isAuthPage) {
        return <Navigate to="/login" replace />;
    } else if (token && isAuthPage) {
        return <Navigate to="/" replace />;
    }

    return children;
};

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const [token, setToken] = useState(localStorage.getItem('biscoff'));

    useEffect(() => {
        const biscoff = localStorage.getItem('biscoff')
        if (!!biscoff) {
            setToken(biscoff)
        }
    }, [])

    const handleLogin = async ({ username, password }) => {
        const { access } = await login({ username, password })
        setToken(access);
        localStorage.setItem('biscoff', access);
        navigate('/');
    };

    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem('biscoff');
        navigate("/login")
    };

    const handleSignup = async (body) => {
        const { username } = await register(body);
        //   setToken(access);
        //   localStorage.setItem('biscoff', access);
        //   setCookie(config.cookieName, token);
        //   setCookie(config.roleCookieName, role);
        //   setCookie(config.nameCookieName, name);
        await handleLogin({ username, password: body.password })
        //   navigate('/');
    }

    const value = {
        token,
        onLogin: handleLogin,
        onLogout: handleLogout,
        onSignup: handleSignup
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}

const PageWithHeader = (children) => {
    return (
        <div>
            <ResponsiveAppBar />
            <Box sx={{ mt: 12 }}>{children}</Box>
        </div>
    );
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route index element={<Navigate to="/dashboard" />} />
                        <Route
                            path="/dashboard"
                            element={<ProtectedRoute>{PageWithHeader(<DashboardPage />)}</ProtectedRoute>}
                        />
                        <Route
                            path="/assessment"
                            element={<ProtectedRoute>{PageWithHeader(<AssessmentPage />)}</ProtectedRoute>}
                        />
                        <Route
                            path="/report"
                            element={<ProtectedRoute>{PageWithHeader(<ReportPage />)}</ProtectedRoute>}
                        />
                        <Route path="/login" element={<ProtectedRoute isAuthPage><LoginPage /></ProtectedRoute>} />
                        <Route path="/register" element={<ProtectedRoute isAuthPage><RegisterPage /></ProtectedRoute>} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default App;
