import { Box, Button, Paper, Typography } from "@mui/material";

export default function SetGoals1(props) {
    const { setActiveStep } = props;
    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "76px",
                borderRadius: "8px",
                px: "24px",
                mb: "16px",
                height: "500px",
            }}
        >
            <Box textAlign={"center"}>
                <Typography
                    fontWeight={600}
                    fontSize={"36px"}
                    fontFamily={"Sora"}
                >
                    Let's Get Started:{" "}
                    <span
                        style={{
                            color: `rgba(76, 164, 86, 1)`,
                        }}
                    >
                        Select Your Green Goals
                    </span>
                </Typography>
                <Typography
                    fontSize={"18px"}
                    fontFamily={"Sora"}
                    color="#8F8F8F"
                >
                    These goals will guide the strategies suggested later in the
                    assessment. Select only the goals that you want to achieve
                    for this period (e.g., 6 months, 1 year, or more).
                </Typography>
                <Button
                    color="success"
                    variant="contained"
                    sx={{
                        // background: "#2EAF7D",
                        boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                        borderRadius: "24px",
                        width: "40%",
                        height: "56px",
                        mt: "24px",
                        textTransform: "none",
                    }}
                    onClick={() => {
                        setActiveStep(1);
                    }}
                >
                    <Typography fontWeight={600} fontSize={"24px"}>
                        I Understand, Let's Continue
                    </Typography>
                </Button>
                <Typography
                    fontWeight={700}
                    fontSize={"20px"}
                    mt={"30px"}
                    color="#979797"
                    onClick={() => {
                        window.location.href = "/";
                    }}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    Back to Home
                </Typography>
            </Box>
        </Paper>
    );
}
