import { Box, Button, Paper, Typography } from "@mui/material";

export default function SetGoals3(props) {
    const { setActiveStep } = props;
    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "76px",
                borderRadius: "8px",
                px: "24px",
                mb: "16px",
                height: "500px",
            }}
        >
            <Box textAlign={"center"}>
                <Typography
                    fontWeight={600}
                    fontSize={"36px"}
                    fontFamily={"Sora"}
                >
                    Setting Your{" "}
                    <span
                        style={{
                            color: `rgba(76, 164, 86, 1)`,
                        }}
                    >
                        Priorities
                    </span>
                </Typography>
                <Typography
                    fontSize={"18px"}
                    fontFamily={"Sora"}
                    color="#8F8F8F"
                >
                    You've taken the first step by selecting your Green Goals!
                    Next, we need to know which goals are most important to your
                    organization. This will help us recommend the most relevant
                    and impactful strategies to help you achieve your goals.
                </Typography>
                <Button
                    color="success"
                    variant="contained"
                    sx={{
                        // background: "#2EAF7D",
                        boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                        borderRadius: "24px",
                        width: "40%",
                        height: "56px",
                        mt: "24px",
                        textTransform: "none",
                    }}
                    onClick={() => {
                        setActiveStep(3);
                    }}
                >
                    <Typography fontWeight={600} fontSize={"24px"}>
                        I Understand, Let's Continue
                    </Typography>
                </Button>
                <Typography
                    fontWeight={700}
                    fontSize={"20px"}
                    mt={"30px"}
                    color="#979797"
                    onClick={() => {
                        setActiveStep(1);
                    }}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    Back to Choose Goal
                </Typography>
            </Box>
        </Paper>
    );
}
