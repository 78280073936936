import {
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchGoals, getActions } from "../../../api/rules";

export default function ActionPlan() {
  const [results, setResults] = useState([]);
  const [goals, setGoals] = useState([]);
  const [actions, setActions] = useState([]);

  const fetch = async () => {
    try {
      const acs = await getActions();
      const data = await fetchGoals({ assessment: false });
      setGoals(data.data);
      setActions(acs.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    const results = JSON.parse(localStorage.getItem("end_results"));
    if (results) {
      const result = [];

      results.forEach((item) => {
        if (!result[item.goalID]) {
          result[item.goalID] = {
            goalID: item.goalID,
            achievementScore: 0,
            maturityScore: 0,
          };
        }

        if (item.achievementScore !== undefined) {
          result[item.goalID].achievementScore = item.achievementScore;
        }

        if (item.maturityScore !== undefined) {
          result[item.goalID].maturityScore = item.maturityScore;
        }
      });

      setResults(result);
    }
  }, []);

  return (
    <>
      {results && goals.length > 0 && (
        <>
          <Typography fontWeight={600} fontSize={"24px"} marginBottom={"24px"}>
            Green Goals Maturity
          </Typography>
          <Paper
            sx={{
              boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "76px",
              borderRadius: "8px",
              px: "24px",
              mb: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontWeight={600}
                  style={{ flex: 1 }}
                  fontSize={"24px"}
                >
                  Goals Name
                </Typography>
                <Stack direction={"row"} spacing={5}>
                  <div style={{ textAlign: "center" }}>Initial</div>
                  <div style={{ textAlign: "center" }}>Managed</div>
                  <div style={{ textAlign: "center" }}>Defined</div>
                  <div style={{ textAlign: "center" }}>Quant. Managed</div>
                  <div style={{ textAlign: "center" }}>Optimizing</div>
                </Stack>
              </div>
              {Object.values(results).map((task) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ flex: 1 }}
                    fontSize={"18px"}
                  >
                    {goals.find((goal) => goal.goalID === task.goalID).goalName}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={task.maturityScore * 20}
                    style={{
                      marginLeft: "16px",
                      width: "50%",
                    }}
                  />
                </div>
              ))}
            </div>
          </Paper>
          <Typography
            fontWeight={600}
            fontSize={"24px"}
            marginTop={"24px"}
            marginBottom={"24px"}
          >
            Green Goals Achievement
          </Typography>
          <Grid container spacing={2}>
            {Object.values(results).map((task) => (
              <Grid item>
                <Paper
                  sx={{
                    boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                    maxWidth: "min-content",
                    borderRadius: "8px",
                    p: "24px",
                  }}
                >
                  <Typography align="center" fontWeight={600} mb="24px">
                    {goals.find((goal) => goal.goalID === task.goalID).goalName}
                  </Typography>
                  <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={240}
                    height={240}
                    borderRadius="50%"
                    border="1px solid #ccc"
                  >
                    <CircularProgress
                      variant="determinate"
                      value={task.achievementScore}
                      size={200}
                      thickness={5}
                      sx={{
                        "& .MuiCircularProgress-circle": {
                          strokeLinecap: "round",
                          color: "green",
                        },
                      }}
                    />
                    <Typography
                      variant="h4"
                      component="div"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {parseFloat(task.achievementScore).toLocaleString()}%
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Paper
            sx={{
              boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "76px",
              borderRadius: "8px",
              padding: "1rem",
              marginTop: "1rem",
              px: "24px",
              mb: "16px",
            }}
          >
            <Typography
              fontWeight={600}
              textAlign={"center"}
              fontSize={24}
              style={{ paddingBottom: "1.5rem" }}
            >
              Here are the{" "}
              <span style={{ color: "#4CA456" }}>recommended action plans</span>{" "}
              based on your assessment
            </Typography>
            <Typography
              fontWeight={600}
              fontSize="24px"
              style={{ paddingBottom: "2rem" }}
            >
              Top Strategies to Prioritize
            </Typography>
            {actions &&
              actions.slice(0, 10).map((v, i) => (
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  style={{ paddingBottom: "1rem" }}
                >
                  {v.strategyName}
                </Typography>
              ))}
            <Typography
              fontWeight={400}
              fontSize={16}
              textAlign="center"
              style={{ paddingTop: "4rem" }}
            >
              Save the action plans above and show it to your dashboard by
              clicking the Create Action Plan button below.
            </Typography>
            <Button
              color="success"
              variant="contained"
              sx={{
                // background: "#2EAF7D",
                boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                borderRadius: "24px",
                width: "40%",
                height: "56px",
                alignItems: 'center',
                textTransform: "none",
                margin: '2rem auto 1.5rem auto'
              }}
              onClick={() => {  
                window.location.href = "/dashboard";
              }}
            >
              <Typography fontWeight={600} fontSize={"24px"}>
                Create Action Plan
              </Typography>
            </Button>
          </Paper>
        </>
      )}
    </>
  );
}
