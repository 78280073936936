import axios from "axios";
import config from "../config";
// import Cookies from "universal-cookie"

// const cookies = new Cookies()
const baseURL = config.baseURL;
// const cookieName = config.cookieName;

const request = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
    },
});

request.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('biscoff')
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
});

export default request;
