import { Box, Button, Paper, Typography } from "@mui/material";

export default function Strategies1(props) {
    const { setActiveStep } = props;
    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "76px",
                borderRadius: "8px",
                px: "24px",
                mb: "16px",
                height: "500px",
            }}
        >
            <Box textAlign={"center"}>
                <Typography
                    fontSize={"18px"}
                    fontFamily={"Sora"}
                    color="#8F8F8F"
                >
                    You've identified the Green Goals that matter most to you
                    and rated their importance.
                </Typography>
                <Typography
                    fontWeight={600}
                    fontSize={"36px"}
                    fontFamily={"Sora"}
                    sx={{
                        my: "16px",
                    }}
                >
                    Now, let's assess the implementation of the strategies{" "}
                    <span
                        style={{
                            color: `rgba(76, 164, 86, 1)`,
                        }}
                    >
                        implementation of the strategies
                    </span>{" "}
                    related to those goals.
                </Typography>
                <Typography
                    fontSize={"18px"}
                    fontFamily={"Sora"}
                    color="#8F8F8F"
                >
                    This will help us determine the next steps in achieving your
                    sustainability objectives. Please rate your organization's
                    current level of implementation for each strategy provided.{" "}
                </Typography>
                <Button
                    color="success"
                    variant="contained"
                    sx={{
                        boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                        borderRadius: "24px",
                        width: "40%",
                        height: "56px",
                        mt: "24px",
                        textTransform: "none",
                    }}
                    onClick={() => {
                        setActiveStep(1);
                    }}
                >
                    <Typography fontWeight={600} fontSize={"24px"}>
                        Next
                    </Typography>
                </Button>
                <Typography
                    fontWeight={700}
                    fontSize={"20px"}
                    mt={"30px"}
                    color="#979797"
                    onClick={() => {
                        window.location.href = "/assessment";
                    }}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    Back to Set Goals Importance
                </Typography>
            </Box>
        </Paper>
    );
}
