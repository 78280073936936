// @ts-nocheck
import { Box, Button, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { fetchGoals } from "../../../../api/rules";

export default function SetGoals2(props) {
    const { setActiveStep } = props;
    const [answers, setAnswers] = useState([]);
    const [goals, setGoals] = useState();

    const fetch = async () => {
        try {
            const data = await fetchGoals({ assessment: true });
            setGoals(data.data);
        } catch (err) {
            console.log(err.message);
        }
    };

    useEffect(() => {
        localStorage.removeItem("goals");
        fetch();
    }, []);

    const handleCheckboxChange = (optionId, checked) => {
        if (checked) {
            setAnswers((prevAnswers) => [...prevAnswers, optionId]);
        } else {
            setAnswers((prevAnswers) =>
                prevAnswers.filter((answer) => answer !== optionId)
            );
        }
    };

    const handleSubmit = () => {
        localStorage.setItem("goals", JSON.stringify(answers));
        setActiveStep(2);
    };

    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                // display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                borderRadius: "8px",
                px: "24px",
                mb: "16px",
                height: "500px",
                pt: "24px",
            }}
        >
            <Box>
                <Typography
                    fontWeight={600}
                    fontSize={"24px"}
                    fontFamily={"Sora"}
                    textAlign={"center"}
                >
                    Which{" "}
                    <span
                        style={{
                            color: `rgba(76, 164, 86, 1)`,
                        }}
                    >
                        Green Goals
                    </span>{" "}
                    that you want to achieve in this period?
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            width: "80%",
                            height: 300, // Replace with desired height
                            overflowY: "auto",
                            mt: "24px",
                            borderRadius: "4px",
                            border: "1px solid #E0E0E0",
                        }}
                    >
                        <FormGroup>
                            {goals
                                ? goals.map((goal) => (
                                      <FormControlLabel
                                          key={goal.goalID}
                                          sx={{
                                              my: "8px",
                                          }}
                                          control={
                                              <Checkbox
                                                  checked={answers.includes(
                                                      goal.goalID
                                                  )}
                                                  sx={{
                                                      "& .MuiSvgIcon-root": {
                                                          fontSize: 28,
                                                      },
                                                  }}
                                                  color="success"
                                                  onChange={(event) =>
                                                      handleCheckboxChange(
                                                          goal.goalID,
                                                          event.target.checked
                                                      )
                                                  }
                                              />
                                          }
                                          labelPlacement="end"
                                          label={
                                              <>
                                                  <Typography fontWeight={700}>
                                                      {goal.goalName}
                                                  </Typography>
                                                  <Typography
                                                      fontWeight={400}
                                                      color={"#8F8F8F"}
                                                  >
                                                      {goal.goalDescription}
                                                  </Typography>
                                              </>
                                          }
                                      />
                                  ))
                                : null}
                        </FormGroup>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <Button
                        color="success"
                        variant="contained"
                        sx={{
                            // background: "#2EAF7D",
                            boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                            borderRadius: "24px",
                            width: "20%",
                            height: "56px",
                            mt: "24px",
                            textTransform: "none",
                        }}
                        onClick={handleSubmit}
                        disabled={answers.length === 0}
                    >
                        <Typography fontWeight={600} fontSize={"24px"}>
                            Next
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
}
