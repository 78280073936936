import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

const dummyData = [
    {
        level: (
            <Typography fontWeight={600} align="center">
                N <br />
                (None)
            </Typography>
        ),
        description: "Means that the practice is not initialized.",
    },
    {
        level: (
            <Typography fontWeight={600} align="center">
                P <br />
                (Partially)
            </Typography>
        ),
        description: "Means that the practice is 15%-49% performed.",
    },
    {
        level: (
            <Typography fontWeight={600} align="center">
                L <br />
                (Largely)
            </Typography>
        ),
        description: "Means that the practice is 50%-84% performed.",
    },
    {
        level: (
            <Typography fontWeight={600} align="center">
                F <br />
                (Fully)
            </Typography>
        ),
        description: "Means that the practice is 85%-100% performed.",
    },
    {
        level: (
            <Typography fontWeight={600} align="center">
                N/A <br />
                (Not Applicable)
            </Typography>
        ),
        description:
            "Means that the practice is not applicable for your organization.",
    },
];

export default function Practices2(props) {
    const { setActiveStep } = props;
    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "76px",
                borderRadius: "8px",
                p: 4,
                mb: "16px",
                height: "100%",
            }}
        >
            <Box textAlign={"center"}>
                <Typography
                    fontSize={"18px"}
                    fontFamily={"Sora"}
                    color="#8F8F8F"
                >
                    In the next section,
                </Typography>
                <Typography
                    fontWeight={600}
                    fontSize={"36px"}
                    fontFamily={"Sora"}
                    sx={{
                        my: "16px",
                    }}
                >
                    there are 4 levels of practice implementation
                </Typography>
                <TableContainer sx={{ mb: "24px" }}>
                    <Table sx={{ minWidth: 500 }}>
                        <TableHead sx={{ backgroundColor: "#8ED974" }}>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    sx={{ color: "black", fontWeight: "bold" }}
                                >
                                    Level
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ color: "black", fontWeight: "bold" }}
                                >
                                    Description
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dummyData.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        "&:nth-of-type(odd)": {
                                            backgroundColor: "#f5f5f5",
                                        },
                                    }}
                                >
                                    <TableCell>{row.level}</TableCell>
                                    <TableCell align="center">
                                        {row.description}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography
                    fontSize={"18px"}
                    fontFamily={"Sora"}
                    color="#8F8F8F"
                >
                    You can see the explanation above by clicking the “i” button
                    on the next page.
                </Typography>
                <Button
                    color="success"
                    variant="contained"
                    sx={{
                        boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                        borderRadius: "24px",
                        width: "40%",
                        height: "56px",
                        mt: "24px",
                        textTransform: "none",
                    }}
                    onClick={() => {
                        setActiveStep(2);
                    }}
                >
                    <Typography fontWeight={600} fontSize={"24px"}>
                        I Understand, Let's Continue
                    </Typography>
                </Button>
                <Typography
                    fontWeight={700}
                    fontSize={"20px"}
                    mt={"30px"}
                    color="#979797"
                    onClick={() => {
                        setActiveStep(0);
                    }}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    Back to Previous Page
                </Typography>
            </Box>
        </Paper>
    );
}
