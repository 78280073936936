import { Box, Button, Paper, Typography } from "@mui/material";

export default function Practices1(props) {
    const { setActiveStep, setActiveStepMain } = props;
    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "76px",
                borderRadius: "8px",
                px: "24px",
                mb: "16px",
                height: "500px",
            }}
        >
            <Box textAlign={"center"}>
                <Typography
                    fontSize={"18px"}
                    fontFamily={"Sora"}
                    color="#8F8F8F"
                >
                    You have identified the implementation level of each Green
                    Strategy.
                </Typography>
                <Typography
                    fontWeight={600}
                    fontSize={"36px"}
                    fontFamily={"Sora"}
                    sx={{
                        my: "16px",
                    }}
                >
                    Moving on to{" "}
                    <span
                        style={{
                            color: `rgba(76, 164, 86, 1)`,
                        }}
                    >
                        Assessing Green Practices
                    </span>
                </Typography>
                <Typography
                    fontSize={"18px"}
                    fontFamily={"Sora"}
                    color="#8F8F8F"
                >
                    In the next section, You will be presented with a list of
                    practices related to your Green Strategies. Please rate your
                    organization's current level of implementation for each
                    practices provided.
                </Typography>
                <Button
                    color="success"
                    variant="contained"
                    sx={{
                        boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                        borderRadius: "24px",
                        width: "40%",
                        height: "56px",
                        mt: "24px",
                        textTransform: "none",
                    }}
                    onClick={() => {
                        setActiveStep(1);
                    }}
                >
                    <Typography fontWeight={600} fontSize={"24px"}>
                        Next
                    </Typography>
                </Button>
                <Typography
                    fontWeight={700}
                    fontSize={"20px"}
                    mt={"30px"}
                    color="#979797"
                    onClick={() => {
                        setActiveStepMain(1);
                    }}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    Back to Set Assess Strategies
                </Typography>
            </Box>
        </Paper>
    );
}
