import request from "./base";

export const login = async (creds) => {
  const res = await request.post('/users/login/', creds)
  if (res.data) {
    return res.data
  } else {
    throw new Error('Empty response')
  }
}

export const register = async (data) => {
  const res = await request.post('/users/register/', data)
  if (res.data) {
    return res.data
  } else {
    throw new Error('Empty response')
  }
}
