import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

const dummyData = [
    {
        level: (
            <Typography fontWeight={600} align="center">
                0 <br />
                Incomplete/Not Exist
            </Typography>
        ),
        description:
            "No formal process is in place, and there is no control over the process. The organization does not recognize the importance of the process and has not allocated resources to it.",
        implementation: (
            <List
                sx={{ "& li::before": { content: "'•'", paddingRight: "8px" } }}
            >
                <ListItem>
                    <ListItemText primary="No documented policies or procedures" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="No awareness or training" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="No controls or measures" />
                </ListItem>
            </List>
        ),
    },
    {
        level: (
            <Typography fontWeight={600} align="center">
                1 <br />
                Initial
            </Typography>
        ),
        description:
            "The process is ad hoc, and there is no formalization. The organization is reactive and deals with issues as they arise.",
        implementation: (
            <List
                sx={{ "& li::before": { content: "'•'", paddingRight: "8px" } }}
            >
                <ListItem>
                    <ListItemText primary="Policies and procedures are in a draft stage and not formally approved" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Processes are ad hoc and may vary by user or group" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="No awareness or training programs exist" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Controls and measures are limited or non-existent" />
                </ListItem>
            </List>
        ),
    },
    {
        level: (
            <Typography fontWeight={600} align="center">
                2 <br />
                Repeatable
            </Typography>
        ),
        description:
            "The process is consistent and there are basic controls in place. However, the process is not yet formalized, and there may be some inconsistencies in how it is performed.",
        implementation: (
            <List
                sx={{ "& li::before": { content: "'•'", paddingRight: "8px" } }}
            >
                <ListItem>
                    <ListItemText primary="Policies and procedures are formally approved and communicated" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Processes are repeatable but not documented or standardized" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Some awareness or training programs are in place" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Controls and measures are informal or reactive" />
                </ListItem>
            </List>
        ),
    },
    {
        level: (
            <Typography fontWeight={600} align="center">
                3 <br />
                Defined
            </Typography>
        ),
        description:
            "The process is defined and standardized, and there is a formalized document that specifies how the process should be performed. Roles and responsibilities are defined, and there is a clear understanding of what needs to be done.",
        implementation: (
            <List
                sx={{ "& li::before": { content: "'•'", paddingRight: "8px" } }}
            >
                <ListItem>
                    <ListItemText primary="Policies and procedures are formalized and documented" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Processes are defined and standardized" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Formal training and awareness programs are in place" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Controls and measures are defined and implemented" />
                </ListItem>
            </List>
        ),
    },
    {
        level: (
            <Typography fontWeight={600} align="center">
                4 <br />
                Managed
            </Typography>
        ),
        description:
            "The process is measured and controlled, and there are metrics in place to monitor its performance. Performance targets are set and monitored, and there is a process for reporting on performance.",
        implementation: (
            <List
                sx={{ "& li::before": { content: "'•'", paddingRight: "8px" } }}
            >
                <ListItem>
                    <ListItemText primary="Policies and procedures are reviewed and updated regularly" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Processes are monitored and measured against performance goals" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Comprehensive training and awareness programs are in place" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Controls and measures are regularly monitored and reported on" />
                </ListItem>
            </List>
        ),
    },
    {
        level: (
            <Typography fontWeight={600} align="center">
                5 <br />
                Optimized
            </Typography>
        ),
        description:
            "The process is continually improved, and there is a focus on innovation and optimization. Best practices are shared across the organization, and there is a culture of continuous improvement.",
        implementation: (
            <List
                sx={{ "& li::before": { content: "'•'", paddingRight: "8px" } }}
            >
                <ListItem>
                    <ListItemText primary="Policies and procedures are continuously improved based on best practices" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Processes are continuously reviewed, assessed, and optimized" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Training and awareness programs are continuously updated and improved" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Controls and measures are continuously monitored, assessed, and optimized" />
                </ListItem>
            </List>
        ),
    },
];

export default function Strategies2(props) {
    const { setActiveStep } = props;
    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "76px",
                borderRadius: "8px",
                p: 4,
                mb: "16px",
                height: "100%",
            }}
        >
            <Box textAlign={"center"}>
                <Typography
                    fontSize={"18px"}
                    fontFamily={"Sora"}
                    color="#8F8F8F"
                >
                    In assessing the strategies,{" "}
                </Typography>
                <Typography
                    fontWeight={600}
                    fontSize={"36px"}
                    fontFamily={"Sora"}
                    sx={{
                        my: "16px",
                    }}
                >
                    there are 6 levels of strategy implementation
                </Typography>
                <TableContainer sx={{ mb: "24px" }}>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead sx={{ backgroundColor: "#8ED974" }}>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    sx={{ color: "black", fontWeight: "bold" }}
                                >
                                    Level
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ color: "black", fontWeight: "bold" }}
                                >
                                    Description
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ color: "black", fontWeight: "bold" }}
                                >
                                    Implementation
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dummyData.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        "&:nth-of-type(odd)": {
                                            backgroundColor: "#f5f5f5",
                                        },
                                    }}
                                >
                                    <TableCell>{row.level}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>{row.implementation}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography
                    fontSize={"18px"}
                    fontFamily={"Sora"}
                    color="#8F8F8F"
                >
                    You can see the explanation above by clicking the button on
                    the bottom part of the next page.{" "}
                </Typography>
                <Button
                    color="success"
                    variant="contained"
                    sx={{
                        boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                        borderRadius: "24px",
                        width: "40%",
                        height: "56px",
                        mt: "24px",
                        textTransform: "none",
                    }}
                    onClick={() => {
                        setActiveStep(2);
                    }}
                >
                    <Typography fontWeight={600} fontSize={"24px"}>
                        I Understand, Let's Continue
                    </Typography>
                </Button>
                <Typography
                    fontWeight={700}
                    fontSize={"20px"}
                    mt={"30px"}
                    color="#979797"
                    onClick={() => {
                        setActiveStep(0);
                    }}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    Back to Previous Page
                </Typography>
            </Box>
        </Paper>
    );
}
