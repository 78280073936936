// @ts-nocheck
import {
    Box,
    Button,
    Paper,
    Typography,
    Divider,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { useEffect, useState } from "react";
import { postStrategies } from "../../../../api/rules";

const options = [
    { value: 0, label: "Incomplete/Not Exist" },
    { value: 1, label: "Initial" },
    { value: 2, label: "Repeatable" },
    { value: 3, label: "Defined" },
    { value: 4, label: "Managed" },
    { value: 5, label: "Optimized" },
];

export default function Strategies3(props) {
    const { setActiveStepMain } = props;
    const [strategies, setStrategies] = useState();
    const [answer, setAnswer] = useState({});

    const handleChange = (questionId, value) => {
        setAnswer((prevAnswer) => ({
            ...prevAnswer,
            [questionId]: value,
        }));
    };

    useEffect(() => {
        const strategies = JSON.parse(localStorage.getItem("strategies"));
        if (strategies) {
            setStrategies(strategies);
        }
    }, []);

    const handleSubmit = async () => {
        if (Object.keys(answer).length !== strategies.length) {
            alert("Please answer all questions");
            return;
        }
        const data = Object.values(answer).map((value, index) => ({
            strategyID: strategies[index].strategyID,
            maturityScore: value,
        }));
        const response = await postStrategies({ data: data });
        if (response.ok === true) {
            localStorage.setItem("practices", JSON.stringify(response.data));
            setActiveStepMain(2);
        }
    };

    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                // display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                borderRadius: "8px",
                px: "24px",
                mb: "16px",
                height: "500px",
                pt: "24px",
            }}
        >
            {/* {loading && <Typography>Loading...</Typography>} */}
            {strategies && (
                <Box>
                    <Typography
                        fontWeight={600}
                        fontSize={"24px"}
                        fontFamily={"Sora"}
                        textAlign={"center"}
                        sx={{
                            mb: "24px",
                        }}
                        height="100%"
                    >
                        How is the implementation of this{" "}
                        <span
                            style={{
                                color: `rgba(76, 164, 86, 1)`,
                            }}
                        >
                            Green Strategy
                        </span>{" "}
                        in your organization?
                    </Typography>
                    <Box
                        sx={{
                            overflowY: "scroll",
                            height: "35vh",
                        }}
                    >
                        {strategies.map((strategy, index) => (
                            <Box textAlign={"center"} key={strategy.strategyID}>
                                <Typography
                                    fontWeight={600}
                                    fontSize={"32px"}
                                    fontFamily={"Sora"}
                                    my={2}
                                >
                                    <span
                                        style={{
                                            fontSize: "20px",
                                        }}
                                    >
                                        Strategy {index + 1}/{strategies.length}
                                        :{" "}
                                    </span>
                                    {strategy.strategyName}
                                </Typography>
                                <Typography
                                    fontSize={"16px"}
                                    fontFamily={"Sora"}
                                    color="#8F8F8F"
                                >
                                    {strategy.strategyDescription}
                                </Typography>
                                <Box sx={{ mb: 2, p: 2 }}>
                                    <FormControl
                                        component="fieldset"
                                        sx={{ mt: 2 }}
                                    >
                                        <RadioGroup
                                            name={index}
                                            value={
                                                answer[index] !== undefined
                                                    ? answer[index]
                                                    : ""
                                            }
                                            onChange={(event) =>
                                                handleChange(
                                                    index,
                                                    parseInt(event.target.value)
                                                )
                                            }
                                        >
                                            {options.map(({ value, label }) => (
                                                <FormControlLabel
                                                    key={value}
                                                    value={value.toString()}
                                                    control={<Radio />}
                                                    label={label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                <Divider />
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        <Button
                            color="success"
                            variant="contained"
                            sx={{
                                boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                                borderRadius: "24px",
                                width: "40%",
                                height: "56px",
                                mt: "24px",
                                textTransform: "none",
                            }}
                            onClick={handleSubmit}
                            disabled={
                                Object.keys(answer).length !== strategies.length
                            }
                        >
                            <Typography fontWeight={600} fontSize={"24px"}>
                                Continue to Assess Practice
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            )}
        </Paper>
    );
}
