import { Menu, MenuItem, Paper, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import "./DashboardPage.css";
import { ArrowDropDownRounded, Info } from "@mui/icons-material";
import { mockGoals } from "./mocks";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Bar from "../../components/BarChart/BarChart";
import { fetchSummary } from "../../api/rules";
import empty from "../../assets/images/empty.png";

function EmptyState() {
    return (
        <Paper
            sx={{
                boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "76px",
                borderRadius: "8px",
                px: "24px",
                py: "1.5rem",
                mb: "16px",
            }}
        >
            <Typography textAlign="center" fontSize={28} fontWeight={600} color="#B2B2B2">
                Oops, You haven’t done any assessment before!
            </Typography>
            <img src={empty} alt="No Assessment Yet" width={560} height={380} />
            <Button
                color="success"
                variant="contained"
                sx={{
                    // background: "#2EAF7D",
                    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.12)`,
                    borderRadius: "24px",
                    width: "40%",
                    height: "56px",
                    alignItems: 'center',
                    textTransform: "none",
                    margin: '2rem auto 1.5rem auto'
                }}
                onClick={() => {
                    window.location.href = "/assessment";
                }}
            >
                <Typography fontWeight={600} fontSize={"24px"}>
                    Start Green IT Assessment
                </Typography>
            </Button>
        </Paper>
    )
}

export default function DashboardPage() {
    const [data, setData] = useState(mockGoals);
    const [whichList, setWhichList] = useState("Goals");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const fetch = async () => {
        try {
            const sm = await fetchSummary()
            setData(sm)
        } catch (err) {
            console.log(err.message)
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuClick = (list) => {
        setWhichList(list);
        setAnchorEl(null);
    };

    useEffect(() => {
        setAnchorEl(null);
    }, [whichList]);

    useEffect(() => {
        // setData(mockGoals);
        fetch();
    }, []);

    if (!!data == false || data.data.length == 0) {
        return (
            <Container
                sx={{
                    width: 1,
                    mb: 5,
                }}
            >
                <EmptyState />
            </Container>
        )
    }

    return (
        <Container
            sx={{
                width: 1,
                mb: 5,
            }}
        >
            <Paper
                sx={{
                    boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    minHeight: "160px",
                    background: `linear-gradient(
                            90deg,
                            rgba(42, 129, 52, 0.9) 0%,
                            rgba(49, 105, 55, 0.640625) 50.08%,
                            rgba(0, 0, 0, 0.5) 100%
                          ),
                          url("https://www.itb.ac.id/files/dokumentasi/1618210624-DSC_8353.JPG")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "8px",
                    mb: "24px",
                }}
            >
                <div className="dashboard-header">
                    <div className="div1">
                        <Typography
                            fontSize={"36px"}
                            color={"white"}
                            fontWeight={"bold"}
                            fontFamily={"Sora"}
                        >
                            Institut Teknologi Bandung
                        </Typography>
                    </div>
                    <div className="div2">
                        <Typography
                            fontSize={"16px"}
                            color={"white"}
                            fontWeight={"bold"}
                            fontFamily={"Sora"}
                        >
                            Ongoing Goals: 7 goals
                        </Typography>
                    </div>
                    <div className="div3">
                        <Typography
                            fontSize={"16px"}
                            color={"white"}
                            fontWeight={"bold"}
                            fontFamily={"Sora"}
                        >
                            Ongoing strategies: 7 strategies
                        </Typography>
                    </div>
                    <div className="div4">
                        <Typography
                            fontSize={"16px"}
                            color={"white"}
                            fontWeight={"bold"}
                            fontFamily={"Sora"}
                        >
                            Last assessment: 01/04/2023
                        </Typography>
                    </div>
                </div>
            </Paper>
            <Paper
                sx={{
                    boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    minHeight: "76px",
                    borderRadius: "8px",
                    px: "24px",
                    mb: "16px",
                }}
            >
                <div className="dashboard-header-name">
                    <div className="div1">
                        <Stack
                            direction={"row"}
                            justifyContent={"left"}
                            alignItems={"center"}
                            id="demo-positioned-button"
                            aria-controls={
                                open ? "demo-positioned-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                        >
                            <Typography
                                fontSize={"32px"}
                                color={"black"}
                                fontWeight={"bold"}
                                fontFamily={"Sora"}
                            >
                                {whichList}
                            </Typography>
                            <ArrowDropDownRounded
                                sx={{
                                    fontSize: "60px",
                                    color: "#B2B2B2",
                                }}
                            />
                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <MenuItem
                                    onClick={() => handleMenuClick("Goals")}
                                >
                                    Goals
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        handleMenuClick("Strategies")
                                    }
                                >
                                    Strategies
                                </MenuItem>
                            </Menu>
                        </Stack>
                    </div>
                    <div className="div2">
                        <Stack
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <Typography
                                fontSize={"20px"}
                                color={"black"}
                                fontWeight={"bold"}
                                fontFamily={"Sora"}
                            >
                                Goal Achievement
                            </Typography>
                            <Info
                                sx={{
                                    fontSize: "20px",
                                    color: "#B2B2B2",
                                    ml: "8px",
                                }}
                            />
                        </Stack>
                    </div>
                    <div className="div3">
                        <Stack
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <Typography
                                fontSize={"20px"}
                                color={"black"}
                                fontWeight={"bold"}
                                fontFamily={"Sora"}
                            >
                                Goal Maturity
                            </Typography>
                            <Info
                                sx={{
                                    fontSize: "20px",
                                    color: "#B2B2B2",
                                    ml: "8px",
                                }}
                            />
                        </Stack>
                    </div>
                    <div className="div4">
                        <Stack
                            direction={"row"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <Typography
                                fontSize={"20px"}
                                color={"black"}
                                fontWeight={"bold"}
                                fontFamily={"Sora"}
                            >
                                Strategies Maturity
                            </Typography>
                            <Info
                                sx={{
                                    fontSize: "20px",
                                    color: "#B2B2B2",
                                    ml: "8px",
                                }}
                            />
                        </Stack>
                    </div>
                </div>
            </Paper>
            {data.data && data.data.map((goal) => (
                <Paper
                    key={goal.goalID}
                    sx={{
                        boxShadow: `0px 0px 16px 2px rgba(0, 0, 0, 0.12)`,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        minHeight: "76px",
                        borderRadius: "8px",
                        px: "24px",
                        mb: "16px",
                        height: "max-content",
                    }}
                >
                    <div className="dashboard-header-name">
                        <div className="div1">
                            <Stack
                                direction={"row"}
                                justifyContent={"left"}
                                alignItems={"center"}
                            >
                                <Typography
                                    fontSize={"20px"}
                                    color={"black"}
                                    fontWeight={"600"}
                                    fontFamily={"Sora"}
                                >
                                    {goal.goalName}
                                </Typography>
                            </Stack>
                        </div>
                        {goal.goalAchievement && <div className="div2">
                            <Stack
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Typography
                                    fontSize={"48px"}
                                    color={"rgba(91, 180, 98, 1)"}
                                    fontWeight={"400"}
                                    fontFamily={"Sora"}
                                >
                                    {goal.goalAchievement.toFixed(2)}%
                                </Typography>
                            </Stack>
                        </div>}
                        {goal.goalMaturity && <div className="div3">
                            <Stack
                                direction={"row"}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Typography
                                    fontSize={"48px"}
                                    color={"rgba(91, 180, 98, 1)"}
                                    fontWeight={"400"}
                                    fontFamily={"Sora"}
                                >
                                    {goal.goalMaturity}
                                </Typography>
                            </Stack>
                        </div>}
                        {goal.strategiesMaturity &&
                            <div className="div4">
                                <Stack
                                    direction={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    <Bar goalsData={goal.strategiesMaturity} />
                                </Stack>
                            </div>}
                    </div>
                </Paper>
            ))}
        </Container>
    );
}
