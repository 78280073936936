import { useState } from "react";
import SetGoals1 from "../SetGoals/SetGoals1";
import SetGoals2 from "../SetGoals/SetGoals2";
import SetGoals3 from "../SetGoals/SetGoals3";
import SetGoals4 from "../SetGoals/SetGoals4";

export default function SetGoals(props) {
    const { setActiveStepMain } = props;
    const [activeStep, setActiveStep] = useState(0);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <SetGoals1 setActiveStep={setActiveStep} />;
            case 1:
                return <SetGoals2 setActiveStep={setActiveStep} />;
            case 2:
                return <SetGoals3 setActiveStep={setActiveStep} />;
            case 3:
                return <SetGoals4 setActiveStepMain={setActiveStepMain} />;
            default:
                throw new Error("Unknown step");
        }
    }
    return <>{getStepContent(activeStep)}</>;
}
